<template>
    <div class="ktiphone_container">
        <!-- <div class="ad-page"></div> -->
        <alert-modal
            v-if="isOverGenreModal"
            :alertText="`해당 이벤트는 9/13(금) 21시부터 <br /> 신청가능합니다.`"
            @closeModal="onCloseOverGenreModal"
        />
        <div class="image-section">
            <picture>
                <source :srcset="mo_src_a" media="(max-width: 768px)">
                <img class="view-image" :src="pc_src_a">
            </picture>
        </div>
        <div  v-show="this.$common.compareYearMonthDayTime('2024-09-10 14:00','2024-09-19 23:59')" class="button-section" >
            <button class="section-button" @click="eventClick">{{ button_text }}<i class="arrow-icon"></i></button>
        </div>
        <div class="image-section">
            <picture>
                <source :srcset="mo_src_b" media="(max-width: 768px)">
                <img class="view-image" :src="pc_src_b">
            </picture>
        </div>

    </div>
</template>
<script>
import { isNotPc } from '@/utils/Utils';
export default {

  name: 'KtIphone',
  components: {
    'alert-modal': () => import(`@/components/modal/Space/AlertModal.vue`)
  },
  data () {
    return {
      pc_src_a: require("../../../../public/media/img/event/pc_kt_iphone16_1.png"),
      pc_src_b: require("../../../../public/media/img/event/pc_kt_iphone16_2.png"),
      mo_src_a: require("../../../../public/media/img/event/mo_kt_iphone16_1.png"),
      mo_src_b: require("../../../../public/media/img/event/mo_kt_iphone16_2.png"),
      button_text: 'iPhone 16 사전예약',
      isPc: !isNotPc(),
      isOverGenreModal: false
    };
  },

  mounted () {},

  methods: {

    eventClick () {
      const startOpenEvent = new Date('2024-09-10 21:00');
      const curDate = new Date();

      if (curDate.getTime() < startOpenEvent.getTime()) {
        this.isOverGenreModal = true;
      } else {
        this.isOverGenreModal = false;
        this.isPc = !isNotPc();
        let openUrl = 'https://shop.kt.com/display/olhsPlan.do?plnDispNo=2462&cmpid=shop_wless_240913-banner-event_iphone16_unpack-aplayz';
        if (!this.isPc) {
          openUrl = 'https://m.shop.kt.com:444/m/display/olhsPlan.do?plnDispNo=2462&cmpid=mshop_wless_240913-banner-event_iphone16_unpack-aplayz';
        }
        window.open(openUrl, '_blank');
      }
    },
    onCloseOverGenreModal () {
      this.isOverGenreModal = false;
    }
  }

};
</script>
<style src="@/assets/css/content2.css"></style>
<style scoped>
.ktiphone_container {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    background-color: var(--020202);
    padding-bottom: 67px;
}

.ktiphone_container .image-section{
    max-width: 800px;
    overflow:hidden;
    margin:0 auto;
}
.image-section .view-image {
    width:100%;
    height:100%;
    object-fit:cover;
}
.ktiphone_container .button-section{
    max-width: 800px;
    margin: 50px 0 50px 0;
    text-align: center;
    background-color: #020202;

}
button {
    display :inline-block;
    width: 412px;
    height: 63px;
    font-size: 26px;
    color: #ffffff;
    background-color: #FF2D55;
    margin: 0 auto;
}
button .arrow-icon {
    width: 22px;
    height: 17px;
    margin-left: 12px;
    display: inline-block;
    background-image: url("../../../../public/media/img/event/arrow4.png");
    background-size: contain;
}

@media all and (max-width: 768px) {
    .ktiphone_container .button-section{
        width: 100%;
    }
    button {
        width: 234px;
        height: 48px;
        font-size: 16px;
    }
    button .arrow-icon {
        width: 20px;
        height: 12px;
        margin-left: 10px;
    }
}

</style>
